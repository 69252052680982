.connect-tech-settings__section {
  margin-top: 24px;
}

.connect-tech-settings__main-nav-section {
  display: flex;
  flex-direction: row;
  background-color: #fff;
  height: 75px;
  border-radius: 16px;

  &__icon {
    width: 40px;
    height: 40px;
    text-align: center;
    line-height: 40px;
    margin: 16px;
  }

  &__text-area {
    flex: 1;
    margin-top: 16px;
  }

  &__title {
    font-family: 'Montserrat', sans-serif;
    font-weight: 700;
    color: #332C4A;
    font-size: 16px;
    line-height: 22px;
    margin-bottom: 2px;

    &.centered {
      line-height: 42px;
    }
  }

  &__subtitle {
    font-weight: normal;
    font-size: 14px;
    line-height: 19px;
    color: #646466;
  }

  &__arrow {
    line-height: 10px;
    margin: 32px 24px;
  }
}

.connect-tech-settings__main-availability {
  &__day-container {
    display: flex;
  }

  &__day {
    margin-right: 4px;
    color: #d0cfd4;

    &.active {
      color: #332c4a;
    }
  }
}

.connect-tech-settings__skillset-section {
  margin-bottom: 16px;
}
