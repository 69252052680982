.routing-container {
  height: calc(100% - 100px);
  overflow-y: scroll;
  position: fixed;
  width: 100%;
}

.button-container {
  width: 100%;
  height: 113px;
  background: #fff;
  position: fixed;
  bottom: 0;
  border-radius: 20px 20px 0 0;
  box-shadow: 0 -4px 4px rgba(0, 0, 0, 0.03);
  display: flex;
  align-items: center;
  padding: 24px;
  flex-direction: row;
}

.justify-center {
  justify-content: center;
}

.justify-space {
  justify-content: space-between;
}

.back-button {
  width: 96px;
  border-radius: 15px;
  height: 48px;
  background: #d4ebff;
  border: none;
  color: #004dcb;
}

.next-button {
  width: 215px;
  border-radius: 15px;
  height: 48px;
  background: #004dcb;
  border: none;
  color: white;
}

.full-button {
  width: 350px;
}

button:focus {
  outline: none;
}
