@import '../../../styles/colors.scss';

.main {
  width: 100%;
  min-height: 100%;
  background: $white;
  padding: 16px;
  position: relative;
}

.fullWidth {
  padding: 24px 0;
  width: 100%;
  min-height: 100vh;
  background: $inputWhite;
  position: relative;
}

.loading {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}

.header {
  display: flex;
  margin-bottom: 10px;
}

.title {
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  margin-right: 25px;
  text-align: center;
  width: 100%;
}
