@import '../../../styles/colors.scss';

.inputContainer {
  flex-grow: 1;
  max-height: 54px;
  height: 54px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  border-radius: 16px;
  margin-top: 8px;
  background: $inputWhite;
}

.inputIcon {
  margin-left: 16px;
}

.inputContent {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin-left: 16px;
  width: 100%;
  margin-right: 30px;
}

.label {
  padding: 0;
  margin: 0;
  border: 0;
  position: absolute;
  color: $textGrayMediumDark;
  transition: all 0.2s ease-in-out;
  transform-origin: left top;
  font-size: 12px;
  cursor: text;
  pointer-events: none;
  transform: none;
}

.labelActive {
  transform: translate3d(0, -40%, 0) scale(0.75);
}

.input {
  width: 100%;
  padding: 0;
  margin: 15px 0 0;
  font-size: 16px;
  line-height: 24px;
  border: none;
  outline: none;
  transition: all 0.2s ease-in-out;
  transform-origin: left bottom;
  transform: none;
  display: flex;
  background-color: transparent;
}

.indicator {
  position: absolute;
  right: 13px;
  top: 13px;

  img {
    width: 30px;
    height: 30px;
  }
}
