@import '../../../styles/mixins.scss';
@import '../../../styles/colors.scss';

.highlights {
  &__container {
    height: 214px;
  }

  &__top {
    height: 50%;
    width: 100%;
    border-bottom: 1px solid $athensGray;
    padding: 22px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }

  &__bottom {
    height: 50%;
    width: 100%;
    display: flex;
    flex-direction: row;
  }

  &__best {
    padding: 16px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-around;
    width: 50%;
    height: 100%;
  }

  &__right_border {
    border-right: 1px solid $athensGray;
  }

  &__amount {
    @include text-lato-bold;

    font-weight: bold;
    font-size: 20px;
    line-height: 24px;
    color: $bgGrayDarkest;
    height: 40%;
  }

  &__amount_lifetime {
    @include text-lato-bold;

    font-weight: bold;
    font-size: 24px;
    line-height: 29px;
    color: $bgGrayDarkest;
  }

  &__label {
    @include text-lato-bold;

    font-size: 12px;
    line-height: 14px;
    color: $lightGray;
  }

  &__header {
    @include text-lato-normal;

    font-size: 14px;
    line-height: 17px;
    color: $bgGrayDarkest;
  }

  &__lifetime_earnings {
    width: 55%;
    height: 100%;
    background: white;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-around;
  }
}
