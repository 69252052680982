html,
body {
  margin: 0;
  padding: 0;
  font-family: 'Lato', sans-serif;
  width: 100%;
  height: 100%;
  min-width: 320px;
  overflow-x: hidden;
}

#root {
  height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
