@import '../../../styles/colors.scss';

.select_filter {
  &__filter {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: calc(100% - 32px);
    background: $whisper2;
    padding: 16px;
    margin: 16px;
    border-radius: 16px;
  }

  &__selector {
    border: none;
    background: none;
    outline: none;
    appearance: none;
    text-indent: calc(50% - 3em);
    width: 100%;
  }

  &__selector:focus {
    outline: none;
  }
}
