.range_selector_view {
  &__header {
    height: 84px;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 16px;
  }

  &__header_content {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  &__title {
    font-family: Montserrat, sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
  }

  &__arrow {
    width: 8px;
  }

  &__range_type_selector {
    background: #fff;
    border: 1px solid #dfdfe9;
    border-radius: 16px;
    width: calc(100% - 32px);
    margin-left: 16px;
    margin-bottom: 16px;
    height: 60px;
    padding: 8px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }

  &__range_type_selection {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 33%;
    height: 100%;
    border-radius: 8px;
  }

  &__range_type_selected {
    background: #f8f8fb;
  }

  &__apply_button {
    position: fixed;
    width: 327px;
    height: 48px;
    left: 50%;
    transform: translateX(-50%);
    bottom: 48px;
    background: #004dcb;
    border-radius: 16px;
    font-family: Montserrat, sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.02em;
    text-transform: uppercase;
    color: #fff;
  }

  &__range_provider {
    height: calc(100vh - 84px - 16px - 60px);
    width: 100%;
    overflow: hidden;
    padding: 16px;
  }
}
