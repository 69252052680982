@import '../../../styles/mixins.scss';
@import '../../../styles/colors.scss';

.job_info {
  &__container {
    width: 100%;
    height: 67px;
    padding: 16px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }

  &__description {
    width: 75%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
  }

  &__job_address {
    @include text-lato-normal;

    font-size: 14px;
    line-height: 120%;
    color: $bgGrayDarkest;
  }

  &__job_label {
    @include text-lato-normal;

    font-size: 12px;
    line-height: 120%;
    color: $lighterGrey;
  }

  &__job_cost {
    width: 25%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    color: $bgGrayDarkest;
  }

  &__job_eta {
    @include text-lato-normal;

    font-size: 12px;
    line-height: 120%;
    color: $bgGrayDarkest;
  }

  &__job_eta_grey {
    color: $lighterGrey;
  }
}
