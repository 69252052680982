.toggle-item {
  margin-bottom: 16px;

  &__top-section {
    height: 56px;
    background-color: #fff;
    border-radius: 16px;
    color: #332C4A;
    font-family: 'Montserrat', sans-serif;
    font-size: 18px;
    font-weight: 600;
    line-height: 56px;
    padding-left: 24px;
    padding-right: 16px;
    display: flex;
    justify-content: space-between;

    &__flat {
      border-radius: 16px 16px 0 0;
    }

    &__btn {
      background-color: transparent;
      border: none;

      &:focus {
        outline: none;
      }
    }
  }

  &__icon {
    width: 40px;
    height: 40px;
    text-align: center;
    line-height: 40px;
    margin-left: -7px;
    margin-right: 12px;
  }

  &__divider {
    margin-left: 24px;
    background-color: #f0f5fc;
    height: 1px;
  }

  &__bottom-section {
    height: 56px;
    background-color: #fff;
    border-bottom-left-radius: 16px;
    border-bottom-right-radius: 16px;
    color: #646466;
    font-size: 16px;
    font-weight: 400;
    line-height: 56px;
    padding-left: 24px;
    padding-right: 24px;
    display: flex;
    justify-content: space-between;

    &__arrow {
      width: 6px;
      height: 10px;
      margin-top: 23px;

      svg {
        fill: #d0cfd4;
      }
    }
  }

  &__top-section + &__bottom-section {
    &__top-section {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }
  }
}
