.selected-reason-header {
  margin-bottom: 2rem;
  padding-left: 8px;
  padding-right: 8px;

  &__label {
    color: #646466;
    font-size: 12px;
    margin-bottom: 5px;
  }
}

.selected-reason {
  min-width: 0;
  display: flex;
  align-items: center;

  &__value {
    flex: 1 1 auto;
    color: black;
    font-size: 20px;
    line-height: 1.2;
    font-weight: 700;
    font-family: 'Montserrat', sans-serif;
  }

  &__back {
    margin-left: 0.5rem;
  }
}

.form {
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;

  &__submit {
    margin-top: auto;
  }
}
