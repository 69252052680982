@import '../../../styles/colors.scss';

.range_display {
  &__filter {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 183px;
    background: $whisper2;
    color: $bgGrayDarkest;
    padding: 17.5px;
    border-radius: 16px;
    height: 100%;
  }

  &__container {
    width: 100%;
    padding: 16px;
    height: 88px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }

  &__navigation {
    width: 56px;
    height: 100%;
    background: $whisper2;
    border-radius: 16px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }

  &__navigation:disabled {
    img {
      filter: contrast(0%);
    }
  }

  &__selector {
    border: none;
    background: none;
    outline: none;
    appearance: none;
    text-align-last: center;
  }

  &__selector:focus {
    outline: none;
  }

  &__back_arrow {
    transform: rotate(180deg);
  }
}
