@import '../../../styles/colors.scss';

.button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 14px 21px;
  height: 48px;
  background: $solitudeLight;
  border-radius: 16px;
  cursor: pointer;
  outline: none;
  color: $blueButton;
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  border: none;
  width: 100%;

  & img {
    margin-right: 6px;
  }
}
