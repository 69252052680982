@import './../../styles/colors.scss';

.content {
  color: $bgGrayDarkest;
  display: flex;
  flex-direction: column;
  text-align: center;
}

.statusImg {
  width: 150px;
  height: 150px;
  align-self: center;
  margin: 30px auto;
}

.inputs {
  display: flex;
  flex-direction: row;

  :last-child {
    margin-left: 15px;
  }

  & input {
    text-align: end;
  }
}

.title {
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 22px;
}

.copy {
  margin-top: 10px;
  font-size: 14px;
}

.url {
  margin-top: 24px;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 140%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 24px;
  height: 68px;
  background: $whisper2;
  border-radius: 16px;
}

.button {
  position: absolute;
  bottom: 48px;
  color: $white;
  background: #0565FF;
  text-transform: uppercase;
  width: calc(100% - 48px);

  &:disabled {
    cursor: not-allowed;
    background: #D4E3FC;
  }
}

.buttonPublished {
  background: $textRed;
}

.buttonHidden {
  background: $blueButton2;
}

.header {
  margin-top: 40px !important;
  text-align: left;
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 800;
  font-size: 32px;
  line-height: 110%;
  color: $bgGrayDarkest;
}

.warning {
  @extend .copy;

  text-align: justify;
  margin-bottom: 20px;
}

.point {
  text-align: left;
  margin-top: 10px;
  position: relative;

  &__icon {
    position: absolute;
    width: 24px;
    height: 24px;
    display: inline-block;
  }

  &__title {
    margin-left: 35px;
    margin-bottom: 0;
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
  }

  &__text {
    margin-left: 35px;
    margin-bottom: 8px;
    font-size: 14px;
    line-height: 17px;
    white-space: pre-wrap;
  }

  &__button {
    margin: 0 0 0 35px;
    width: 121px;
    height: 40px;
  }
}

.confirm {
  bottom: 110px;
  left: 0;
  width: 100%;
  position: absolute;
  text-align: center;
}
