@import '../../../styles/colors.scss';

.avatar {
  width: 76px;
  height: 76px;
  background: $white;
  border-radius: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;

  img {
    max-width: 76px;
  }
}
