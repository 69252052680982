@import '../../../styles/mixins.scss';
@import '../../../styles/colors.scss';

.job_info_view {
  &__header {
    height: 84px;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 16px;
  }

  &__container {
    width: 100%;
    padding: 16px;
    background: #f8f8fb;
    min-height: 100vh;
  }

  &__list_body {
    height: calc(100vh - 84px - 32px);
    width: 100%;
    overflow-y: scroll;
    padding: 16;
  }

  &__header_content {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__title {
    font-family: Montserrat, sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
  }

  &__date {
    @include text-lato-bold;

    font-size: 14px;
    line-height: 17px;
  }

  &__arrow {
    width: 8px;
  }

  &__loading_container {
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
}
