@import '../../../styles/colors.scss';

.header {
  width: 100%;
  height: 76px;
  padding: 0 16px;
  display: flex;
}

.avatar {
  width: 76px;
  height: 76px;
}

.headerContent {
  display: flex;
  margin-left: 16px;
  flex-direction: column;
  justify-content: center;
}

.name {
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 29px;
  white-space: nowrap;
  color: $bgGrayDarkest;
}

.url {
  margin-top: 4px;
  color: $greyDarker;
}
