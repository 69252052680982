.list-selected-indicator {
  $indicator-size: 28px;

  width: $indicator-size;
  height: $indicator-size;
  background-color: #e5efff;
  border-radius: 50%;
  color: #07f;
  font-size: 12px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}
