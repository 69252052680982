.connect-tech-settings {
  background-color: #f6f9fe;
  padding-bottom: 10px;
  min-height: 100%;

  &__warning {
    margin: 15px;
  }

  &__title {
    &__header {
      font-family: "Montserrat", sans-serif;
      font-weight: 600;
      font-size: 24px;
      padding-left: 24px;
      padding-top: 30px;
    }
  }

  &__back-arrow {
    margin-top: 36px;
    margin-left: 16px;
  }

  &__loading {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
  }

  &__error {
    position: absolute;
    width: 210px;
    height: 28px;
    left: 82px;
    top: 32px;
    border-radius: 38px;
    padding: 8px 7px 8px 7px;
    background: #eb5757;
    box-shadow: 0 4px 16px rgba(0, 0, 0, 0.2);
    font-size: 12px;
    text-align: center;
    line-height: 12px;
    color: white;
  }
}

.connect-tech-settings__skillset {
  background-color: #f6f9fe;
  height: 100%;
  overflow: auto;

  &__title {
    margin-bottom: 32px;
    width: 80%;
  }

  &__service {
    height: 56px;
    background-color: #fff;
    color: #332C4A;
    font-family: 'Montserrat', sans-serif;
    font-size: 18px;
    font-weight: 600;
    line-height: 56px;
    margin-bottom: 16px;
    padding-left: 24px;
    padding-right: 16px;
    display: flex;
    justify-content: space-between;

    &__toggle {
      background-color: transparent;
      border: none;

      &:focus {
        outline: none;
      }
    }
  }

  &__overlay {
    height: 100vh;
    width: 100%;
    background-color: #332c4a30;
    position: fixed;
    top: 0;
    z-index: 5;
  }

  &__action-card {
    border-radius: 16px 16px 0 0;
    background-color: #fff;
    position: fixed;
    bottom: 0;
    margin-left: 16px;
    margin-right: 16px;
    padding-bottom: 50px;
    z-index: 10;

    &__title {
      font-family: 'Montserrat', sans-serif;
      font-size: 18px;
      color: #000;
      font-weight: 600;
      margin: 24px 24px 16px 24px;
    }

    &__action {
      height: 56px;
      background-color: #f6f9fe;
      color: #0578ff;
      margin-bottom: 1px;
      font-size: 16px;
      padding-left: 24px;
      padding-top: 20px;
    }
  }

  &__backdrop {
    height: 100%;
    width: 100%;
    position: fixed;
    background-color: rgba(0, 0, 0, 0.5);
    top: 0;
  }

  &__backdrop-high {
    height: 100%;
    width: 100%;
    position: fixed;
    background-color: rgba(0, 0, 0, 0.5);
    top: 0;
    z-index: 100;
  }
}
