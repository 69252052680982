@import '../../../styles/colors.scss';

.menuItem {
  width: 100%;
  background: $white;
  border-radius: 16px;
  margin-top: 16px;
}

.header {
  height: 56px;
  padding: 0 26px;
  display: flex;
  align-items: center;
  font-family: 'Montserrat', sans-serif;
  font-weight: bold;
  font-size: 18px;
  line-height: 22px;
  color: $bgGrayDarkest;
  border-bottom: 1px solid #F0F5FC;

  & img {
    margin-right: 8px;
  }
}

.link {
  height: 56px;
  padding: 0 26px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 16px;
  line-height: 19px;
  color: $linkGrey;
  cursor: pointer;

  &:hover {
    color: $linkGrey;
  }
}

.disabled {
  cursor: default;
}

.textContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
