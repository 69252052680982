@import url(https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700;800&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@400;600;800;900&display=swap);

.main_header {
  font-family: Montserrat, sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  color: rgba(17, 11, 37, 0.9);
}

.recharts-wrapper .recharts-cartesian-grid-horizontal line:first-child,
.recharts-wrapper .recharts-cartesian-grid-horizontal line:last-child {
  stroke-opacity: 0;
}

* {
  -ms-overflow-style: none;
}

button {
  border: none;
  background: none;
  outline: none;
}

::-webkit-scrollbar {
  display: none;
}
