@import './../../styles/colors.scss';

.main {
  width: 100%;
  min-height: 100vh;
  background: $inputWhite;
  padding: 20px 0;
}

.preview {
  width: 100%;
  margin-right: 8px;
}

.buttons {
  padding: 16px;
  display: flex;
  justify-content: space-around;
}

.indicator {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: #17C276;
  margin-right: 8px;
  margin-top: 2px;
}

.indicatorHidden {
  background: $textRed;
}
