@import '../../../styles/colors.scss';

.date_range_grid {
  &__header {
    font-family: Montserrat, sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    width: 100%;
    padding: 15px;
    color: $bgGrayDarkest;
  }

  &__table {
    width: 100%;
    table-layout: fixed;
  }

  &__cell {
    text-align: center;
  }
}
