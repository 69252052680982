@import '../../../../styles/colors';

.icon {
  margin-top: 32px;
  width: 96px;
  align-self: center;
}

.header {
  margin-top: 48px;
  text-align: center;

  &__title {
    font-size: 20px;
    font-weight: 700;
    font-family: 'Montserrat', sans-serif;
    margin-bottom: 8px;
  }

  &__details {
    font-size: 16px;
  }
}

.details {
  margin-top: 24px;
  margin-bottom: 24px;

  &__field {
    display: block;
    background-color: $inputWhite;
    color: $linkGrey;
    padding: 16px;
    font-size: 16px;
    border-radius: 16px;

    &--price {
      position: relative;
      text-align: right;
      padding-left: 32px;

      &::before {
        content: "$";
        position: absolute;
        top: 50%;
        left: 16px;
        transform: translateY(-50%);
        color: lighten($linkGrey, 25%);
      }
    }

    & + & {
      margin-top: 16px;
    }
  }
}

.close {
  margin-top: auto;
}
