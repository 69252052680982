@import '../../../styles/colors.scss';

.toggleContainer {
  flex-grow: 1;
  max-height: 54px;
  height: 54px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  position: relative;
  border-radius: 16px;
  margin-top: 12px;
  background: $inputWhite;
}

.icon {
  margin-left: 16px;
}

.toggleContent {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-left: 16px;
  margin-right: 8px;
  width: 100%;
}

.label {
  color: $textGrayMediumDark;
  font-size: 16px;
  line-height: 24px;
}
