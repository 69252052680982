@import '../../../styles/colors.scss';

.review {
  display: flex;
  flex-direction: column;
  margin-top: 10px;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-family: "Montserrat", sans-serif;
  font-weight: bold;
  font-size: 12px;
  line-height: 15px;
  letter-spacing: 0.04em;

  .visibility {
    display: flex;
    border: none;
    background: transparent;
    margin: 0;
    padding: 0;
    cursor: pointer;
    outline: none;

    img {
      margin-top: -7px;
      margin-right: 5px;
    }
  }

  .visible {
    color: $blueButton;
    text-transform: uppercase;
  }

  .hidden {
    color: $bgGrayDarkest;
    text-transform: uppercase;
  }

  .headerRight {
    display: flex;

    img {
      margin-top: -4px;
      margin-left: 2px;
    }
  }

  .hash {
    margin-left: 8px;
    font-family: "Lato", sans-serif;
    color: $bgGrayDarkest;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
  }

  .removed {
    margin-left: 8px;
    font-family: "Lato", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 14px;
    text-align: right;
    color: $frenchGray;
  }
}

.comments {
  margin: 5px 0 10px;
  text-align: justify;
  font-size: 14px;
  line-height: 17px;
}

.footer {
  font-size: 12px;
  line-height: 14px;
  color: $linkGrey;
  text-align: left;
  margin-bottom: 20px;
}
