@import '../../../styles/mixins.scss';
@import '../../../styles/colors.scss';

.job_stats_chart {
  &__legend {
    width: 100%;
    padding-top: 5px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }

  &__legend_symbol {
    height: 20px;
    width: 20px;
    border-radius: 4px;
    margin-right: 8px;
  }

  &__legend_entry {
    display: flex;
    flex-direction: row;
    margin-right: 8px;
  }

  &__tooltip_container {
    height: 73px;
    background: $bgGrayDarkest;
    box-shadow: 0 8px 40px rgba(0, 0, 0, 0.08);
    border-radius: 8px;
    transform: translateX(-50%);
    padding: 8px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    overflow: visible;
  }

  &__tooltip_date {
    @include text-lato-normal;

    width: 100%;
    font-size: 12px;
    color: rgba(255, 255, 255, 0.55);
    line-height: 14px;
  }

  &__tooltip_values {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }

  &__tooltip_value {
    margin: 5px;
  }

  &__tooltip_legend_value {
    @include text-lato-normal;

    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    font-size: 14px;
    line-height: 17px;
    color: $white;
  }

  &__arrow_down {
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid $bgGrayDarkest;
  }

  &__tooltip_line_container {
    width: 100%;
    border: none;
    transform: translateX(-50%);
    transition: height 1s ease;
  }

  &__tooltip_line {
    width: 1px;
    height: 100%;
    background: $bgGrayDarkest;
  }

  &__fill_completed {
    background: $bgBlueDark;
  }

  &__fill_missed {
    background: $athensGray;
  }

  &__y_axis_text {
    @include text-lato-bold;

    font-size: 12px;
    line-height: 14px;
  }
}
