@import '../../../styles/mixins.scss';
@import '../../../styles/colors.scss';

.performance_dashboard_view {
  &__info_card {
    background: linear-gradient(0deg, #ffe9a7, #ffe9a7), #d9fff7;
    height: 63px;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 14px;
    justify-content: space-between;
  }

  &__info_card_message {
    height: 100%;
    width: 75%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
  }

  &__message_header {
    @include text-lato-bold;

    font-size: 16px;
    line-height: 19px;
    color: #332c4a;
  }

  &__message_body {
    @include text-lato-normal;

    font-weight: 600;
    font-size: 14px;
    line-height: 130%;
    color: #626275;
  }

  &__dashboard_container {
    width: 100%;
    padding: 16px;
    background: #f8f8fb;
    min-height: 100vh;
  }

  &__modal_backdrop {
    width: 100%;
    height: 100%;
    position: fixed;
    left: 0;
    top: 0;
    background-color: rgba(0, 0, 0, 0.6);
  }

  &__legend_entry {
    display: flex;
    flex-direction: row;
  }

  &__fill_completed {
    background: $bgBlueDark;
  }

  &__legend_symbol {
    height: 20px;
    width: 20px;
    border-radius: 4px;
    margin-right: 8px;
  }

  &__legend_header {
    @include text-lato-bold;

    font-size: 20px;
    padding-bottom: 8px;
  }

  &__legend_content {
    @include text-lato-normal;

    font-size: 16px;
  }

  &__fill_missed {
    background: $athensGray;
  }

  &__modal_close {
    width: 44px;
    height: 44px;
    margin-bottom: 16px;
    margin-left: calc(50% - 22px);

    img {
      width: 100%;
      height: 100%;
    }
  }

  &__modal_container {
    width: 90%;
    top: 100px;
    left: 5%;
    position: fixed;
  }

  &__modal {
    background: white;
    border-radius: 16px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: left;
    justify-content: flex-start;
    padding: 20px;
  }

  &__symbol_container {
    width: 30px;
  }
}
