@import '../../../styles/mixins.scss';
@import '../../../styles/colors.scss';

.job_stats {
  &__selector {
    width: 100%;
    height: 56px;
    border-top: 1px solid $athensGray;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 16px;
  }

  &__selector_title {
    @include text-lato-bold;

    font-size: 14px;
    line-height: 17px;
  }

  &__selector_desc {
    @include text-lato-bold;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    font-size: 14px;
    line-height: 17px;
    width: 15%;
  }

  &__missed_earnings_section {
    width: 100%;
    height: 114px;
    border-top: 1px solid $athensGray;
    padding: 24px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
  }

  &__missed_earning_header {
    @include text-lato-normal;

    font-size: 14px;
    line-height: 17px;
  }

  &__missed_earning_est {
    @include text-lato-bold;

    font-size: 24px;
    line-height: 29px;
  }

  &__label {
    @include text-lato-bold;

    font-size: 12px;
    line-height: 14px;
    color: $lightGray;
  }

  &__link {
    color: black;
  }

  &__total_container {
    width: 100%;
    padding-top: 32px;
    text-align: center;
  }

  &__total_value {
    @include text-lato-normal;

    font-weight: 800;
    font-size: 32px;
    line-height: 38px;
    color: $bgBlueDark;
  }

  &__total_text {
    @include text-lato-normal;

    font-size: 14px;
    line-height: 17px;
    color: $bgGrayDarkest;
  }

  &__chart {
    width: 100%;
    height: 350px;
  }
}
