@import '../../../styles/colors.scss';

.connect-tech-settings__car-brands-v2 {
  background-color: $inputWhite;
  position: fixed;
  height: 100%;
  width: 100%;
  display: flex;
  flex-flow: column nowrap;

  &__fixed {
    flex: 0 1 auto;
    background-color: $inputWhite;
    width: 100%;
  }

  &__wrapper {
    min-height: 660px;
    height: 100%;
    position: relative;
    overflow-y: scroll;

    .jobox__onboarding-buttons-container {
      position: absolute;
      bottom: 0;
    }
  }

  &__close-button {
    margin-top: 19px;
    margin-left: 17px;
  }

  &__hightlighted-text {
    color: $blueButton;
  }

  &__title {
    margin: 40px 16px 16px 16px;

    &__header {
      font-family: 'Montserrat', sans-serif;
      font-weight: 600;
      font-size: 24px;
      margin-bottom: 8px;
      line-height: 30px;
    }

    &__subheader {
      font-family: 'Lato', sans-serif;
      font-weight: 400;
      font-size: 16px;
    }
  }

  &__search-container {
    margin-bottom: 6px;
    margin-right: 16px;
    margin-left: 16px;

    input {
      height: 44px;
      padding-left: 32px;
      border: none;
      border-radius: 8px;
      width: 100%;
      background: url(../../../images/icon-search-grey.svg) no-repeat scroll 10px 16.5px;
      background-color: white;
    }

    input::placeholder {
      color: #908D9C;
    }
  }

  &__toggle-filter-button-container {
    margin-bottom: 12px;
    margin-right: 16px;
    margin-left: 16px;

    .jobox__toggle-buttons {
      height: 44px;
      background-color: white;
      border-radius: 8px;
    }

    .jobox__toggle-button {
      font-family: 'Montserrat', sans-serif;
      font-weight: 600;
      font-size: 14px;
    }

    .jobox__toggle-buttons::after {
      border-radius: 8px;
    }
  }

  &__brand-toggles {
    flex: 1 1 auto;
    padding-top: 24px;
    padding-bottom: 115px;
    overflow-y: scroll;
  }

  &__gradient-box {
    background: linear-gradient(to bottom, rgba(246, 249, 254, 1), rgba(0, 0, 0, 0));
    height: 24px;
    width: 100%;
    position: absolute;
  }
}
