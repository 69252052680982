// color palette from the design
$white: #fff;
$black: #000;
$bgBlueDark: #0075ff;
$bgBlueMedium: #0060d0;
$bgBlueLight: #1b77e3;
$bgBlueLighter: #3e8eec;
$bgBlueLightest: #d1e6ff;
$bgBlueMenu: #e8f2ff;
$bgGrayDark: #acaeb0;
$bgGrayDarker: #4a4461;
$bgGrayDarkest: #332c4a;
$bgGrayMedium: #d5d6d7;
$bgGrayLight: #ededed;
$bgGrayLighter: #f5f6f7;
$bgGrayLightest: #f9f9f9;
$bgGreenMedium: #58aa32;
$bgGreenLight: #66bf3d;
$bgGreenLighter: #84ce62;
$bgRedLight: #ffd8d8;
$bgRedLighter: #ffefee;
$lightGray: #a29bba;
$darkGray: #645b83;
$darkerGray: #231c3c;
$grayish: #f8f8f8;
$bgHighLight: #fff7e0;
$bgHighLightDark: #fff2cb;

$textGrayDarkest: #071422;
$textGrayDark: #31353a;
$textGrayMediumDark: #646466;
$textGrayMedium: #656565;
$textGrayLight: #a7a7a7;
$textDarkRed: #cf3030;
$textRed: #eb5757;
$textOrangeRed: #e57056;
$textGreen: #5dcc8a;
$textBlueGreen: #00c5ae;
$textHighlight: #ffed8f;
$transparent: #f000;

$borderColorGrayLight: #ebebeb;

// unnamed colors from the design - http://chir.ag/projects/name-that-color/
$pizza: #bb8415;
$orangePeel: #f1a100;
$mustard: #ffd450;
$creamBrulee: #ffe29f;
$peach: #ffe7b8;
$solitaire: #fef4e5;
$quarterPearlLusta: #fffbf2;
$concrete: #f3f3f3;
$whisper: #f4f4f9;
$whisper2: #f8f8fb;
$athensGray: #eeeef4;
$seashell: #f1f1f1;
$catskillWhite: #f1f5f900;
$solitude: #e2f1ff;
$solitudeLight: #e6f1ff;
$aliceBlue: #f0f7ff;
$pattensBlue: #d7e8ff;
$mischka: #e1dfe6;
$mischka2: #dfdfe9;
$mischkaDarkest: #d1cfd6;
$albescentWhite: #f7e8d6;
$dodgerBlue: #307af6;
$blueRibbon: #006cec;
$chablis: #fff2f2;
$doveGray: #616161;
$white2: #fdfdfd;
$alabaster: #fafafa;
$gray: #818181;
$gray2: #939393;
$gallery: #eee;
$gallery2: #eaeaea;
$usersnapBlue: #3a21ce;
$brownBramble: #583003;
$fireBush: #e3963d;
$tusk: #e6f7d6;
$maiTai: #a96a06;
$martinique: $bgGrayDarkest;
$grannyApple: #dff9e9;
$mercury: #e6e6e6;
$iron: #c8cccf;
$bombay: #b2b8be;
$zircon: #f6faff;
$zumthor: #e6f0ff;
$magicMint: #95f0c4;
$frenchGray: #d0cfd4;
$navajoWhite: #ffd6a5;
$negroni: #ffe3c5;
$serenade: #fff4e8;
$lightPurple: #c7c2d6;
$lighterGrey: #82808b;
$greyDarker: #9594a3;
$blueButton: #07f;
$inputWhite: #f6f9fe;
$linkGrey: #626275;
$blueButton2: #0565ff;
$scienceBlue: #004dcb;
$burntSienna: #f0584e;

// themed
$mainBg: $bgGrayLightest;
$shadowColor: #cecece;

// statuses
$statusVioletBackground: #d8cdfb;
$statusVioletText: #4922ce;
$statusGreenBackground: #c8f0e9;
$statusGreenText: #048b75;
$statusGreen: #05ac91;
$statusYellowBackground: #ffe9c3;
$statusYellowText: #e79100;
$statusYellow: #f9af34;
$statusRedBackground: #ffb8b4;
$statusRedBackgroundHover: #de9c98;
$statusRedText: #e71406;
$statusBlueBackground: #cfe5ff;
$statusBlueBackgroundHover: #c5dbf5;
$statusBlueText: #006fed;
$statusBlue: #248aff;
$statusGrayBackground: $concrete;
$statusGrayText: $textGrayDark;
$statusGray: #c6c6c6;
