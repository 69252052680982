.not-found__container {
  position: fixed;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  left: 50%;
  top: 205px;
  transform: translateX(-50%);
}

.not-found__header {
  font-family: Montserrat, sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 28px;
  line-height: 34px;
  color: rgba(17, 11, 37, 0.9);
  margin: 20px;
}

.not-found__img {
  width: 256px;
}

.not-found__logo {
  position: fixed;
  bottom: 0;
  margin-bottom: 36px;
  width: 74px;
  left: 50%;
  transform: translateX(-50%);
}
