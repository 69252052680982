@import './../../styles/colors.scss';

.content {
  color: $bgGrayDarkest;
  display: flex;
  flex-direction: column;
  text-align: center;
}

.info {
  display: flex;
  width: 100%;
  height: 118px;
  background-color: $inputWhite;
  border-radius: 16px;
  align-items: center;
  justify-content: space-evenly;
  margin: 12px 0 30px;

  .item {
    display: flex;
    flex-direction: column;
  }

  .number {
    color: $blueButton;
    font-size: 44px;
    font-weight: bold;
    line-height: 56px;
  }

  .text {
    color: $linkGrey;
    font-size: 14px;
  }
}
