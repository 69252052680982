@import '../../../styles/mixins.scss';
@import '../../../styles/colors.scss';

.range_grid {
  &__cell {
    @include text-lato-normal;

    height: 44px;
    border: none;
    text-align: center;
    font-size: 16px;
    line-height: 20px;
    color: $bgGrayDarkest;
  }

  &__selected {
    background: $solitudeLight;
    color: $bgBlueDark;
    font-weight: bold;
  }

  &__selected:last-child {
    border-radius: 0 8px 8px 0;
  }

  &__selected:first-child {
    border-radius: 8px 0 0 8px;
  }

  &__disabled {
    color: $lightPurple;
  }

  &__single_highlight,
  &__single_highlight:last-child,
  &__single_highlight:first-child {
    border-radius: 8px;
  }

  &__table {
    width: 100%;
    table-layout: fixed;
  }
}
