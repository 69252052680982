@mixin text-lato-normal {
  font-family: Lato, sans-serif;
  font-style: normal;
  font-weight: normal;
}

@mixin text-lato-bold {
  font-family: Lato, sans-serif;
  font-style: normal;
  font-weight: 500;
}

@mixin select-container {
  width: 100%;
  height: 56px;
  background: #f6f9fe;
  border-radius: 10px;
  border: none;
  padding-left: 16px;
  padding-right: 31px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
