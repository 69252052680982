@import './../../styles/colors.scss';

.main {
  width: 100%;
  min-height: 100vh;
  background: $white;
  padding: 24px;
  position: relative;
}

.content {
  color: $bgGrayDarkest;
  display: flex;
  flex-direction: column;
  padding-bottom: 100px;
}

.topRow {
  display: flex;
  align-items: center;

  & button {
    max-width: 176px;
    margin-left: 16px;
    height: 44px;
  }
}

.upload {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 44px;
  background: $solitudeLight;
  border-radius: 16px;
  cursor: pointer;
  outline: none;
  color: $blueButton;
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  border: none;
  width: 176px;
  margin-left: 16px;

  & label {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  & div {
    padding: 14px 21px;
  }

  & img {
    margin-right: 6px;
  }

  & input {
    display: none;
  }
}

.label {
  font-family: 'Montserrat', sans-serif;
  text-align: left;
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  margin-bottom: 4px;
  margin-top: 32px;
}

.inputs {
  display: flex;
  flex-direction: row;

  :last-child {
    margin-left: 15px;
  }

  & input {
    text-align: end;
  }
}

.copyBox {
  margin: 12px 8px 0;
}

.copy {
  margin: 12px 8px 0;
  font-size: 14px;
  text-align: left;
}

.bold {
  text-align: left;
  font-size: 14px;
  font-weight: 600;
  margin-right: 4px;
}

.button {
  position: absolute;
  bottom: 48px;
  color: $white;
  background: #0565FF;
  text-transform: uppercase;
  width: calc(100% - 48px);

  &:disabled {
    cursor: not-allowed;
    background: #D4E3FC;
  }
}
